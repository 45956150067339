body {
  font-family: Roboto, sans-serif;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color:white;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.input-sin-bordes {

  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline: none;
  border-bottom: 2px solid #cdcdcd;
  border-top: none;
  border-left: none;
  border-right: none;

}

.ant-layout{
  min-height: 100vh;
}


/* .ant-layout-sider {

  flex: 0 0 250px !important;
  max-width: 250px !important;
  max-height: 250px !important;
  width: 250px !important;

}

.ant-layout-sider-collapsed {

  flex: 0 0 80px !important;
  max-width: 80px !important;
  max-height: 80px !important;
  width: 80px !important;

} */

.logo {

  background-color: white;
  margin: 0;
  height: 64px;

}

@media (max-width: 767px) {

  .ant-layout-sider-collapsed {

    flex: 0 0 80px !important;
    width: 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;

  }

  .logo {
    display: none;
  }

  .ant-layout-header p {
    display: none;
  }

  .ant-layout-header img {
    display: none;
  }

}

.ant-layout-header img {
  margin: 10px;
}

.ant-layout-header h5 {

  line-height: 30px;

}

.ant-layout-header p {

  line-height: 20px;

}

main {

  height: 85vh;
  overflow-y: scroll;
  background-color: transparent !important;

}

main::-webkit-scrollbar {

  width: 0px;

}

.ant-layout-header div .badge {

  top: 16px;
  right: -6px;

}