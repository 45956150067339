.green {
    color: green;
}

.red {
    color: red;
}

.quill {
    background-color: white;
}

.rdt_TableCol {

    background-color: #F04E2C;
    color: white;

}